<template>
  <section class="project--partner-header">
    <div class="d-flex justify-space-between align-items-end">
      <div class="col">
        <div class="wrapper--brand my-2" v-if="projectPartner.developer_picture_url">
          <img :src="projectPartner.developer_picture_url" :alt="projectPartner.developer_name" />
        </div>
        <div class="wrapper--info">
          <h1 class="info--name">{{ projectPartner.project_name }}</h1>
          <h2 class="info--desc">
            {{ projectPartner.project_address }}
          </h2>
        </div>
      </div>
      <div class="col-auto">
        <share :url="$router.currentRoute.path"></share>
      </div>
    </div>
    <div class="section--gallery">
      <client-only>
        <vue-gallery-slideshow
          :images="galleryImages"
          :index="imageIndex"
          @close="imageIndex = null"
        ></vue-gallery-slideshow>
      </client-only>
      <v-tabs>
        <v-tab :href="`#tab-1`" v-if="projectPartner.pano_embed_url">{{
          $t(`general.panorama`)
        }}</v-tab>
        <v-tab
          :href="`#tab-2`"
          v-if="
            projectPartner.primary_photo ||
            (projectPartner.list_photos && projectPartner.list_photos.length > 0)
          "
          >{{ $t(`general.gallery`) }}</v-tab
        >

        <v-tab-item :value="'tab-1'" v-if="projectPartner.pano_embed_url">
          <iframe
            class="display--photos"
            :src="projectPartner.pano_embed_url"
            allowfullscreen="allowfullscreen"
            loading="lazy"
          >
          </iframe>
        </v-tab-item>
        <v-tab-item
          :value="'tab-2'"
          v-if="
            projectPartner.primary_photo ||
            (projectPartner.list_photos && projectPartner.list_photos.length > 0)
          "
        >
          <div @click="showImage">
            <img
              class="display--photos"
              :src="projectPartner.primary_photo"
              :alt="projectPartner.project_name"
            />
            <button class="btn btn-primary--outline open--gallery" @click="showImage">
              <i class="ion-images"></i>
              <span v-if="projectPartner.list_photos !== null"
                >{{ $t('general.view1') }} {{ projectPartner.list_photos.length }}
                {{ $t('general.view2') }}</span
              >
            </button>
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
  </section>
</template>

<script>
const VueGallerySlideshow = () => import('vue-gallery-slideshow');
const Share = () => import('@/components/utils/share');
export default {
  props: ['projectPartner'],
  components: {
    VueGallerySlideshow,
    Share,
  },
  data: () => ({
    imageIndex: null,
  }),
  computed: {
    galleryImages() {
      let images = [];
      if (this.projectPartner.list_photos) {
        for (let photo of this.projectPartner.list_photos) {
          images.push({
            url: photo,
            alt: this.projectPartner.project_name + ' - Rentfix',
          });
        }
      }
      return images;
    },
  },
  methods: {
    showImage() {
      this.imageIndex = 0;
    },
  },
};
</script>
